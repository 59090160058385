/*
 * @Author: zhu.xiaoting
 * @Date: 2022-03-23 23:12:22
 * @Last Modified by: zhu.xiaoting
 * @Last Modified time: 2022-04-25 23:07:19
 */
<template>
  <page-head title="消息推送日志" />
  <div class="header">
    <a-form :model="query" :labelCol="{ span: 6 }" :wrapperCol="{ span: 18 }">
      <a-row :gutter="16">
        <a-col :span="6">
          <a-form-item
            label="发布标题"
            style="padding-left: 12px; padding-right: 12px"
          >
            <a-input-search
              v-model:value="query.searchInput"
              placeholder="根据发布标题查询"
              @search="searchData"
            />
          </a-form-item>
        </a-col>
        <a-col :span="18">
          <div class="group-end">
            <a-space>
              <a-button class="button" @click="showTimeSelection">导出</a-button>
              <a-button class="button" @click="deleteData">删除</a-button>
            </a-space>
          </div>
        </a-col>
      </a-row>
    </a-form>
    <a-table
    tableLayout="auto"
    rowKey="id"
    :scroll="{ x: 1720 }"
    :columns="tableData.columns"
    :data-source="tableData.data"
    :loading="tableData.loading"
    :pagination="{
      total: tableData.total,
      current: query.pagination,
    }"
    :row-selection="{
      selectedRowKeys: tableData.selected,
      onChange: onSelectChange,
    }"
    @change="change"
  >
    <template #creationTime="{ text }">
      {{ formatDate(text) }}
    </template>
     <template #status="{ text }">
      {{ text === 0 ? '失败' : '成功' }}
    </template>
  </a-table>
    <a-modal
      v-model:visible="pageData.modalVisible"
      title="起止时间"
      ok-text="确定"
      cancel-text="取消"
      :destroyOnClose="true"
      :confirm-loading="pageData.appConfirmLoading"
      @ok="exportExcel"
      @cancel="closeTimeSelection"
    >
      <div style="margin-bottom: 10px">
        <span style="margin-right: 20px">请选择时段</span>
        <a-range-picker
          showTime
          :locale="locale"
          @change="onChangeTime"
          @ok="onOk"
        ></a-range-picker>
      </div>
    </a-modal>
  </div>
</template>

<script setup>
import { createVNode, toRaw, ref, createApp } from 'vue'
import { message, Modal } from 'ant-design-vue'
import {
  ExclamationCircleOutlined,
} from '@ant-design/icons-vue'
import { useStore } from 'vuex'
import PageHead from '@/components/PageHead'
import { formatDate } from '@/utils/tool'
import {
  query,
  tableData,
  pageData,
} from './data'
import {
  getMessageRecord,
  deleteMessageRecord,
  exportExcelMessageRecord,
} from '@/api/messageLogApi'
import 'moment/locale/zh-cn'
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN'

// 注册组件
const app = createApp()
app.component('page-head', PageHead)

// 获取租户id
const store = useStore()
const tenantId = ref(store.state.account.tenant.tenantId)

// 加载消息记录
const loadData = () => {
  tableData.loading = true
  const queryObj = toRaw(query)
  getMessageRecord(queryObj, tenantId.value)
    .then(({ list: data, pageCount: total }) => {
      data.forEach(obj => {
        obj.statusName = obj.status === 0 ? '失败' : '成功'
        console.log(`status${obj.status}--statusName${obj.statusName}`)
      })
      tableData.loading = false
      tableData.data = data
      tableData.total = total
    })
}

loadData()

/**
 * 搜索数据
 */
const searchData = () => {
  query.pagination = 1
  loadData()
}

/**
 * 搜索重置
 */
const reset = () => {
  query.searchInput = ''
  query.pagination = 1
  loadData()
}

/**
 * 分页变化时触发
 */
const change = ({ current, pageSize }) => {
  query.pageSize = pageSize
  query.pagination = current
  loadData()
}

/**
 * 选择行数据
 */
const onSelectChange = (selectedRow) => {
  tableData.selected = selectedRow
}

const showTimeSelection = () => {
  query.start = null
  query.end = null
  pageData.modalVisible = true
}

const closeTimeSelection = () => {
  pageData.modalVisible = false
}

/**
 * 时间变化时触发
 */
const onChangeTime = (value, dateString) => {
  query.start = value[0]
  query.end = value[1]
}

const onOk = (value) => {
  // console.log('onOk: ', value, pageData)
}

/**
 * Excel数据导出
 */
const exportExcel = () => {
  pageData.appConfirmLoading = true
  if (!query.start || !query.end) {
    pageData.appConfirmLoading = false
    message.warn('请选择时间段！')
    return
  }
  exportExcelMessageRecord(query).then((response) => {
    // console.log(response, '发起请求了')
    // console.log(response.status, '状态码')
    pageData.appConfirmLoading = false
    pageData.modalVisible = false
    if (response.status === 200) {
      var disposition = decodeURI(response.headers['content-disposition'])
      if (disposition) {
        const fileNameMatch = disposition
          ? /filename="?([^"]*?)"?(;|$)/g.exec(disposition)
          : undefined
        const fileName =
          fileNameMatch && fileNameMatch.length > 1
            ? fileNameMatch[1]
            : undefined
        if (fileName) {
          const blob = new Blob([response.data])
          var a = document.createElement('a')
          a.setAttribute('style', 'display: none')
          a.href = window.URL.createObjectURL(blob)
          a.download = fileName
          a.click()
          a.remove()
        } else {
          message.warn('数据导出失败')
        }
      }
    }
  }).catch((err) => {
    console.log(err)
    pageData.appConfirmLoading = false
    pageData.modalVisible = false
    message.warn('数据导出失败')
  })
}

const deleteData = () => {
  if (tableData.selected.length === 0) {
    message.info('请选择至少一条数据')
  } else {
    const arr = tableData.selected
    if (arr.length > 0) {
      Modal.confirm({
        title: '确定删除?',
        icon: createVNode(ExclamationCircleOutlined),
        cancelText: '取消',
        okText: '确定',
        async onOk () {
          tableData.loading = true
          deleteMessageRecord(toRaw(arr))
            .then(data => {
              message.success('删除成功')
              tableData.loading = false
              reset()
            })
        },
        onCancel () { },
      })
    }
  }
}

</script>

<style lang="scss">
.button {
  margin-left: 20px;
  width: 90px;
}

.ant-modal-confirm-title {
  font-weight: bold !important;
}

.ant-modal-confirm-content {
  margin-left: 0 !important;
}
.ant-modal-footer {
  padding: 20px 16px !important;
}

.full-modal {
  .ant-modal {
    max-width: 100%;
    top: 0;
    padding-bottom: 0;
    margin: 0;
  }
  .ant-modal-content {
    display: flex;
    flex-direction: column;
    height: calc(100vh);
  }
  .ant-modal-body {
    flex: 1;
    padding: 4px;
  }
}
</style>

<style lang="scss" scoped>
.header {
  background-color: #fff;
  padding: 24px;

  .group-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
.bottom {
  margin-top: 20px;
}
</style>
