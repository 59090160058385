import api from '@/api/API'
import axios from 'axios'
import { getEnvVars } from './config'
const { apiUrl } = getEnvVars()
const url = {
  getMessageRecord: '/api/app/publish-message-record/paging-publish-message-record-list-by-tenant',
  deleteMessageRecord: '/api/app/publish-message-record/many-publish-message-record',
  exportExcelMessageRecord: '/api/app/file/export-publish-message',
}

/**
 * 获取企业的消息记录
 * @param {object} query 页面大小 分页 查询 租户id 参数
 * @returns {Promise} 网络请求
 */
export const getMessageRecord = ({ pageSize, pagination, searchInput }, tenantId) => api.get(`${url.getMessageRecord}?paging.pageSize=${pageSize}&paging.pagination=${pagination}&paging.searchInput=${searchInput}&tenantId=${tenantId}`).then(({ data }) => data)

/**
 * 删除指定的单个或者多个消息记录
 * @param {object} arr id集合的对象
 * @returns {Promise} 网络请求
 */
export const deleteMessageRecord = (arr) => {
  const string = '?' + arr.map(item => 'guids=' + item).join('&')
  return api.delete(`${url.deleteMessageRecord}${string}`).then(({ data }) => data)
}

/**
 * 导出消息推送记录Excel
 * @param {object} param 搜索词 起始时间 结束时间
 * @returns {Promise} 网络请求
 */
export const exportExcelMessageRecord = (obj) => {
  const tokenObj = JSON.parse(localStorage.getItem('token'))
  const token = tokenObj.access_token
  const type = tokenObj.token_type
  console.log(token, type)
  return axios({
    url: `${apiUrl}${url.exportExcelMessageRecord}`,
    method: 'POST',
    headers: {
      Authorization: `${type} ${token}`,
    },
    responseType: 'blob',
    data: {
      searchInput: obj.searchInput,
      start: obj.start.format('YYYY-MM-DDTHH:mm:ssZ'),
      end: obj.end.format('YYYY-MM-DDTHH:mm:ssZ'),
      byTenant: true,
    },
  })
}
