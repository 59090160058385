import { reactive } from 'vue'

const columns = [
  {
    title: '模板ID',
    dataIndex: 'templateId',
    key: 'templateId',
    width: 180,
  },
  {
    title: '消息类型',
    dataIndex: 'messageTypeText',
    key: 'messageTypeText',
    width: 160,
  },
  {
    title: '消息名称',
    dataIndex: 'title',
    key: 'title',
    width: 180,
  },
  {
    title: '消息来源',
    dataIndex: 'source',
    key: 'source',
    width: 100,
  },
  {
    title: '消息内容',
    dataIndex: 'content',
    key: 'content',
    width: 280,
  },
  {
    title: '推送渠道',
    dataIndex: 'publishWayText',
    key: 'publishWayText',
    width: 120,
  },
  {
    title: 'url地址',
    dataIndex: 'url',
    key: 'url',
    width: 120,
  },
  {
    title: '推送企业名称',
    dataIndex: 'publishEntName',
    key: 'publishEntName',
    width: 160,
  },
  {
    title: '推送人',
    dataIndex: 'publisher',
    key: 'publisher',
    width: 100,
  },
  {
    title: '推送时间',
    dataIndex: 'creationTime',
    key: 'creationTime',
    width: 160,
    slots: {
      customRender: 'creationTime',
    },
  },
  {
    title: '接收企业名称',
    dataIndex: 'receiveEntName',
    key: 'receiveEntName',
    width: 160,
  },
  {
    title: '接收人',
    dataIndex: 'receiver',
    key: 'receiver',
    width: 100,
  },
  {
    title: '消息级别',
    dataIndex: 'messageLevelText',
    key: 'messageLevelText',
    width: 100,
  },
  {
    title: '状态',
    dataIndex: 'status',
    key: 'status',
    width: 120,
    slots: {
      customRender: 'status',
    },
  },
  {
    title: '失败原因',
    dataIndex: 'statusRemark',
    key: 'statusRemark',
    width: 160,
  },
]

export const query = reactive({
  pageSize: 10,
  pagination: 1,
  searchInput: '',
})

export const tableData = reactive({
  data: [],
  total: 0,
  loading: false,
  selected: [],
  columns,
})

export const pageData = reactive({
  modalVisible: false,
  appConfirmLoading: false,
  startTime: null,
  endTime: null,
})
